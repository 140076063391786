<template>
  <div class="sales-parameter">
    <section class="section-block">
      <SectionTitle title="跨境預約連結設定" @edit="linkSettingDialog = true" />
      <BaseElForm label-position="left" label-width="250px">
        <BaseElFormItem label="店家路徑代碼">
          <div class="secret-text collapse">
            <span>{{ code ? code : '尚未設定' }}</span>
          </div>
        </BaseElFormItem>
        <BaseElFormItem label="跨境預約連結">
          <div class="copy-block">
            <a class="secret-text">
              {{ ecommerceLink }}
            </a>
            <div>
              <BaseElButton
                class="copy-btn"
                type="primary"
                @click.prevent="copyLink(ecommerceLink)"
              >
                複製
              </BaseElButton>
            </div>
          </div>
        </BaseElFormItem>
      </BaseElForm>
    </section>

    <!-- 連結設定 Dialog -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="linkSettingDialog"
      title="編輯連結設定"
      @close="resetForm"
    >
      <BaseElForm
        ref="form"
        :model="formData"
        :rules="formRules"
        label-position="top"
      >
        <BaseElFormItem label="店家路徑代碼設定" prop="code">
          <BaseElInput
            v-model="formData.code"
            :min="6"
            class="dialog-input"
            placeholder="請輸入"
          />
          <p style="font-size: 12px; color: gray;">
            最少6字元小寫英數組合，可包含一個 '-'
            符號，不得有其他特殊符號
          </p>
        </BaseElFormItem>
      </BaseElForm>

      <div slot="footer">
        <BaseElButton plain @click="linkSettingDialog = false">取消</BaseElButton>
        <BaseElButton type="primary" @click="updateCode">儲存</BaseElButton>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, computed, set, watch, nextTick } from 'vue'
import SectionTitle from '@/components/Title/SectionTitle'
import copy from 'clipboard-copy'

import { noEmptyRules, domainRules } from '@/validation'
import formUtils from '@/utils/form'
import configuration from '@/configuration'
import { get } from 'lodash'
export default defineComponent({
  name: 'LinkSettings',
  components: { SectionTitle },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const ecommerceLink = computed(() => {
      const code = get(props, 'content.code')
      if (!code) return '尚未設定'

      return `${configuration('pubAptHost')}/${code}`
    })
    const linkSettingDialog = ref(false)
    const formData = reactive({
      code: '',
    })
    const code = ref('')
    const formRules = reactive({
      code: [
        noEmptyRules(),
        domainRules(),
        { min: 6, message: '最少6個字元' },
      ],
    })
    const form = ref(null)
    const updateCode = async () => {
      if (!(await formUtils.checkForm(form.value))) return
      emit('updatePubApt', formData)
      linkSettingDialog.value = false
    }
    const copyLink = (link) => {
      copy(link)
      window.$message.success('已成功複製連結！')
    }
    const resetForm = () => {
      formUtils.resetForm(form.value)
    }
    const syncData = async () => {
      await nextTick()
      set(formData, 'code', get(props, 'content.code'))
      set(code, 'value', get(props, 'content.code'))
    }
    watch(props, async () => {
      await syncData()
    })
    return {
      linkSettingDialog,
      formData,
      formRules,
      ecommerceLink,
      updateCode,
      form,
      copyLink,
      resetForm,
      code,
    }
  },
})
</script>

<style lang="scss">
.el-switch__label span {
  font-size: 16px;
}
</style>

<style scoped lang="scss">
.secret-text,
.note {
  // width: 100px;
  font-weight: 400;
  @apply text-gray-80;
  letter-spacing: 1px;
}

.collapse {
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.copy-block {
  @apply flex items-center overflow-hidden max-w-[440px];

  a {
    @apply overflow-hidden text-gray-80;
    text-decoration: none;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.copy-btn {
  @apply h-[30px] rounded-full py-[3px] ml-[30px] px-[30px];
}
</style>
