<template>
  <div class="sales-parameter">
    <section class="section-block">
      <SectionTitle title="跨境服務價格設定" @edit="priceSettingDialog = true" />
      <BaseElForm label-position="left" label-width="250px">
        <BaseElFormItem label="收費模式設定">
          <div class="secret-text collapse">
            <span>{{ priceRate ? `${Math.round(priceRate)}%` : '尚未設定' }}</span>
          </div>
        </BaseElFormItem>
      </BaseElForm>
    </section>

    <!-- 連結設定 Dialog -->
    <el-dialog
      width="566px"
      :close-on-click-modal="false"
      :visible.sync="priceSettingDialog"
      title="編輯跨境服務價格設定"
      @close="resetForm"
    >
      <p class="text-gray-80 text-sub mb-[20px]">請注意收費模式僅會影響「實際價格」。若您的服務項目勾選「隱藏實際價格」，客人端頁面則直接顯示「顯示價格」設定。（例如：顯示價格設定為「600到900NTD」或「現場確認」，則跨境服務頁面只會顯示相同的字樣）</p>
      <BaseElForm
        ref="form"
        :model="formData"
        :rules="formRules"
        label-position="top"
      >
        <BaseElFormItem label="收費模式設定" prop="priceRate">
          <div class="flex flex-col">
            <BaseElRadioGroup v-model="formData.rateType" class="flex mb-[8px]">
              <BaseElRadio label="percent" class="flex">
                固定比率 (%)
                <TipInfo width="200" :size="16" class="text-gray-60 ml-[8px]">
                  <div>
                    <p>比率僅會影響跨境預約中「實際價格」的數字。</p>
                    <p>（例如：實際價格設定1000元，比率設定120%，則客人端便顯示為1200元）</p>
                  </div>
                </TipInfo>
              </BaseElRadio>
            </BaseElRadioGroup>
            <BaseElInput
              v-model="formData.priceRate"
              type="number"
              :min="6"
              class="dialog-input"
              placeholder="請輸入"
            />
          </div>
        </BaseElFormItem>
      </BaseElForm>

      <div slot="footer">
        <BaseElButton plain @click="priceSettingDialog = false">取消</BaseElButton>
        <BaseElButton type="primary" @click="updatePriceRate">儲存</BaseElButton>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, set, watch, nextTick } from 'vue'
import SectionTitle from '@/components/Title/SectionTitle'
import TipInfo from '@/components/TipInfo.vue'

import { noEmptyRules, minRules } from '@/validation'
import formUtils from '@/utils/form'
import { get } from 'lodash'
export default defineComponent({
  name: 'PriceSettings',
  components: { SectionTitle, TipInfo },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const priceSettingDialog = ref(false)
    const priceRate = ref('')
    const formData = reactive({
      rateType: 'percent',
      priceRate: '',
    })
    const formRules = reactive({
      priceRate: [noEmptyRules(), minRules(50, '固定比率不可設置小於50%')],
    })
    const form = ref(null)
    const updatePriceRate = async () => {
      if (!(await formUtils.checkForm(form.value))) return
      formData.priceRate = formData.priceRate / 100
      emit('updatePubApt', formData)
      priceSettingDialog.value = false
    }
    const resetForm = () => {
      formUtils.resetForm(form.value)
    }
    const syncData = async () => {
      await nextTick()
      set(formData, 'priceRate', Math.round(get(props, 'content.priceRate') * 100))
      set(priceRate, 'value', Math.round(get(props, 'content.priceRate') * 100))
    }
    watch(props, async () => {
      await syncData()
    })
    return {
      priceSettingDialog,
      formData,
      formRules,
      updatePriceRate,
      form,
      resetForm,
      priceRate,
    }
  },
})
</script>

<style lang="scss">
.el-switch__label span {
  font-size: 16px;
}
</style>

<style scoped lang="scss">
// section {
//   margin-top: 2rem;
//   margin-bottom: 50px;
// }
::v-deep .el-radio__label {
  @apply flex
}
::v-deep .el-input {
  @apply w-full;
}
.secret-text,
.note {
  // width: 100px;
  font-weight: 400;
  @apply text-gray-80;
  letter-spacing: 1px;
}

.collapse {
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.copy-block {
  @apply flex items-center overflow-hidden max-w-[440px];

  a {
    @apply overflow-hidden text-gray-80;
    text-decoration: none;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.copy-btn {
  @apply h-[30px] rounded-full py-[3px] ml-[30px] px-[30px];
}
</style>
