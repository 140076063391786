<template>
  <div>
    <BaseElFormItem label="TapPay Partner Account" prop="config.tapPay.partnerAccount">
      <BaseElInput
        v-model="syncForm.partnerAccount"
        placeholder="請輸入"
      />
    </BaseElFormItem>
    <BaseElFormItem label="TapPay Partner Key" prop="config.tapPay.partnerKey">
      <BaseElInput
        v-model="syncForm.partnerKey"
        placeholder="請輸入"
      />
    </BaseElFormItem>
    <BaseElFormItem label="MerchantId" prop="config.tapPay.merchantId">
      <BaseElInput
        v-model="syncForm.merchantId"
        placeholder="請輸入"
      />
    </BaseElFormItem>
    <BaseElFormItem label="appID" prop="config.tapPay.appID">
      <BaseElInput
        v-model="syncForm.appID"
        placeholder="請輸入"
      />
    </BaseElFormItem>
    <BaseElFormItem label="appKey" prop="config.tapPay.appKey">
      <BaseElInput
        v-model="syncForm.appKey"
        placeholder="請輸入"
      />
    </BaseElFormItem>
  </div>
</template>

<script>
export default {
  name: 'TapPayForm',
  props: {
    formData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    syncForm: {
      get () { return this.formData },
      set (v) { return this.$emit('update', v) },
    },
  },
}
</script>

<style lang="postcss" scoped>

</style>
