<template>
  <el-dialog
    title="編輯服務預收款項設定"
    :visible="true"
    width="610px"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <BaseElForm ref="formRef" :model="formData" :rules="formRules" label-position="top">
      <BaseElFormItem label="收費類型設定" prop="type">
        <BaseElRadioGroup v-model="formData.type" class="flex">
          <div class="radio-group">
            <BaseElRadio
              v-for="option in feeTypeOptions"
              :key="option.label"
              class="inline-flex items-center"
              :label="option.prop"
            >
              <div v-if="option.help" class="inline-flex items-center">
                <span>{{ option.label }}</span>
                <el-tooltip placement="right">
                  <div slot="content">
                    <p class="popover-container">{{ option.help }}</p>
                  </div>
                  <MaterialIcon size="21">help_outline_round</MaterialIcon>
                </el-tooltip>
              </div>
              <div v-else>{{ option.label }}</div>
            </BaseElRadio>
          </div>
        </BaseElRadioGroup>
      </BaseElFormItem>
      <BaseElFormItem v-if="!useNoPayment" ref="amountType" label="收費模式設定：" prop="amountType">
        <BaseElRadioGroup
          v-model="formData.amountType"
          style="margin-bottom: 10px"
          @change="handleChange"
        >
          <div class="radio-group">
            <RadioWithInput
              title="固定金額（$）"
              label="fixed"
              prop="amountType"
            >
              <div slot="help">
                <p class="popover-container">系統將依據「您所設定的固定金額」計算每個服務的收費金額</p>
              </div>
              <BaseElFormItem ref="cash" prop="fixedAmount">
                <BaseElInput
                  v-model="formData.fixedAmount"
                  :disabled="formData.amountType !== 'fixed'"
                  placeholder="請輸入固定訂金金額"
                />
              </BaseElFormItem>
            </RadioWithInput>
            <RadioWithInput
              title="固定比率（%）"
              label="percent"
              prop="amountType"
            >
              <div slot="help">
                <p class="popover-container">系統將根據「每個服務實際價格 X跨境服務價格比率 X 固定比率 」計算每個服務的收款金額</p>
              </div>
              <BaseElFormItem ref="percent" prop="percentAmount">
                <BaseElInput
                  v-model="formData.percentAmount"
                  :disabled="formData.amountType !== 'percent'"
                  placeholder="請輸入百分比"
                />
              </BaseElFormItem>
            </RadioWithInput>
          </div>
        </BaseElRadioGroup>
      </BaseElFormItem>
      <BaseElFormItem v-if="!useNoPayment" label="付款方式設定" prop="PubAptPaymentConfigId">
        <BaseElSelect v-model="formData.PubAptPaymentConfigId" placeholder="請選擇">
          <BaseElSelectOption v-for="item in pubAptPaymentTypes" :key="item.id" :label="item.displayName" :value="item.id" />
        </BaseElSelect>
      </BaseElFormItem>
    </BaseElForm>

    <div slot="footer" class="dialog-footer">
      <BaseElButton plain @click="$emit('close')">取消</BaseElButton>
      <BaseElButton type="primary" @click="handleConfirm">
        確認
      </BaseElButton>
    </div>
  </el-dialog>
</template>

<script>
import { noEmptyRules, isDigitRules, integerRules, minRules } from '@/validation'
import formUtils from '@/utils/form'
import RadioWithInput from '@/components/Form/RadioWithInput.vue'
import { filter, get } from 'lodash'
import { computed, defineComponent, onMounted, set } from 'vue'
import { feeTypes } from '@/config/pubApt'
import { useBaseForm } from '@/use/useForm'

export default defineComponent({
  name: 'EditPubAptPrePaymentDialog',
  components: {
    RadioWithInput,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    useNoPayment: {
      type: Boolean,
      default: false,
    },
    paymentData: {
      type: Array,
      default: () => [],
    },
  },
  setup (props, { emit }) {
    const { formData, formRef, initFormData } = useBaseForm()
    initFormData({
      ...props.data,
      percentAmount: props.data.percentAmount ? Math.round(props.data.percentAmount * 100) : null,
    })
    const formRules = computed(() => {
      const base = {
        type: [noEmptyRules('', true)],
        amountType: [noEmptyRules('', true)],
        PubAptPaymentConfigId: [noEmptyRules('請選擇付款方式', true)],
      }
      if (formData.amountType === 'fixed') base.fixedAmount = [noEmptyRules('請輸入金額', true), isDigitRules(), integerRules(), minRules(5, '請輸入 5 或 5 以上的數值')]
      else base.percentAmount = [noEmptyRules('請輸入百分比', true), isDigitRules(), integerRules(), minRules(50, '請輸入 50 或 50 以上的數值')]

      return base
    })
    const pubAptPaymentTypes = computed(() => {
      if (formData.type === 'cardSecret') return filter(props.paymentData, { verified: true, config: { type: 'tapPay' }})
      else return filter(props.paymentData, { verified: true })
    })
    const feeTypeOptions = computed(() => {
      return filter(feeTypes, { default: props.useNoPayment })
    })
    const handleConfirm = async () => {
      if (!(await formUtils.checkForm(formRef.value))) {
        emit('error')
      } else {
        emit('update', formData)
      }
    }
    const handleChange = async () => {
      if (formData.amountType === 'fixed') {
        formData.percentAmount = null
      } else if (formData.amountType === 'percent') {
        formData.fixedAmount = null
      }
      formUtils.clearValidate(formRef.value)
    }
    onMounted(() => {
      set(formData, get(props.data))
    })
    return {
      pubAptPaymentTypes,
      feeTypeOptions,
      formRef,
      handleConfirm,
      formData,
      formRules,
      handleChange,
    }
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .el-select {
  @apply h-auto;
}

::v-deep .el-select__tags {
  @apply flex-nowrap;
}

::v-deep .el-radio {
  @apply mb-[4px];
}

::v-deep .radio-group {
  gap: 8px !important;
}

.radio-group {
  @apply flex flex-col gap-[8px];
}

.popover-container {
  @apply w-[200px] text-justify;
}

.hint {
  @apply text-gray-60 leading-[17px] text-sm font-normal;
}
</style>
